import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSeven from '../../components/Service/SingleServiceSeven';

import serviceImg1 from "../../assets/img/choose/3.png";
import serviceImg3 from "../../assets/img/choose/home12/3.png";
import serviceImg4 from "../../assets/img/choose/home12/1.png";

import bgImg from '../../assets/img/bg/home12/services-bg.jpg';

const bgStyle = {
    backgroundImage: `url(${bgImg})`,
}

const Service = () => { 
    return (
      <React.Fragment>
        <div
          id="rs-services"
          className="rs-services home12-style"
          style={bgStyle}
        >
          <div className="container">
            <SectionTitle
              sectionClass="sec-title4 text-center mb-50"
              subtitleClass="sub-title"
              subtitle="Istituto Poliziano "
              titleClass="title"
              title="In Evidenza"
            />
            <div className="row">
              <div className="col-lg-4 md-mb-30">
                <SingleServiceSeven
                  serviceClass="services-item"
                  serviceImg={serviceImg3}
                  serviceTitle="POLIZIANO PRESS"
                  catLink="/blog"
                  
                />
              </div>
              
              <div className="col-lg-4 md-mb-30">
                <SingleServiceSeven
                  serviceClass="services-item"
                  serviceImg={serviceImg1}
                  serviceTitle="SPAZIO EVENTI"
                  catLink="/servizi/eventi"
                 
                />
              </div>
              <div className="col-lg-4">
                <SingleServiceSeven
                  serviceClass="services-item"
                  serviceImg={serviceImg4}
                  serviceTitle="SEGRETERIA POLIZIANO"
                  catLink="/scuola/contatti"
                 
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

}

export default Service;