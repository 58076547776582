import React from 'react';
import Slider from "react-slick";

import brandLogo1 from "../../../assets/img/services/home12/1.png";
import brandLogo2 from '../../../assets/img/brand/style3/new/1.png';
import brandLogo3 from "../../../assets/img/brand/style3/new/2.png";
import brandLogo4 from "../../../assets/img/brand/style3/new/3.png";
import brandLogo5 from "../../../assets/img/brand/style3/new/4.png";


const BrandFour = (props) => {
    const { brandBg, brandClass } = props; 

    const brandBgStyle = {
        backgroundImage: `url(${brandBg ? brandBg : ''})`
    }

    const brandBgDefaultStyle = {
        backgroundColor: 'transparent'
    }

    const sliderSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
      <div
        className={
          brandClass ? brandClass : "rs-partner style2 pt-100 md-pt-70"
        }
        style={brandBg ? brandBgStyle : brandBgDefaultStyle}
      >
        <div className="container">
          <Slider {...sliderSettings}>
            <div className="partner-item">
              <a href="/registro">
                <img
                  src={brandLogo1}
                  alt="icona stilizzta con un pc e studente"
                />
                <p style={{ textAlign: "center", color: "#0c8b50" }}>
                  Registro elettronico
                </p>
              </a>
            </div>
            <div className="partner-item">
              <a href="/scuola/trasparente">
                <img src={brandLogo2} alt="" />
                <p style={{ textAlign: "center", color: "#0c8b50" }}>
                  Amministrazione trasparente
                </p>
              </a>
            </div>
            <div className="partner-item">
              <a href="/iscrizioni/iscrizioni-online">
                <img src={brandLogo3} alt="" />
                <p style={{ textAlign: "center", color: "#0c8b50" }}>
                  Iscrizione online
                </p>
              </a>
            </div>
            <div className="partner-item">
              <a href="/servizi/eventi">
                <img src={brandLogo4} alt="" />
                <p style={{ textAlign: "center", color: "#0c8b50" }}>
                  Spazio eventi
                </p>
              </a>
            </div>
            <div className="partner-item">
              <a href="https://maps.app.goo.gl/74uD44h9mVbMLbn8A">
                <img src={brandLogo5} alt="" />
                <p style={{ textAlign: "center", color: "#0c8b50" }}>Mappa</p>
              </a>
            </div>
          </Slider>
        </div>
      </div>
    );
}

export default BrandFour;