import React from 'react';

const TopHeaderTwo = (props) => {
	const { topBarClass, emailAddress, phoneNumber, Location } = props;

    return (
      <div
        className={
          topBarClass ? topBarClass : "topbar-area home8-topbar hidden-md"
        }
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-md-5">
              <ul className="topbar-contact">
                {emailAddress ? (
                  <li>
                    <i className="flaticon-email"></i>
                    <a href={"mailto:" + emailAddress}>{emailAddress}</a>
                  </li>
                ) : (
                  ""
                )}
                {phoneNumber ? (
                  <li>
                    <i className="flaticon-call"></i>
                    <a href={"tel:+" + phoneNumber}>{phoneNumber}</a>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            <div className="col-md-7 text-end">
              <div className="toolbar-sl-share">
                <ul>
                  {Location ? (
                    <li className="opening">
                      {" "}
                      <em>
                        <i className="flaticon-location"></i>
                      </em>
                      {Location}
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <a href="https://www.facebook.com/PolizianoRoma/">
                      <i className="fa fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/explore/locations/1069992693195592/istituto-paritario-poliziano/">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default TopHeaderTwo;