import React, { useState, useEffect } from "react";

import icon1 from "../../assets/img/choose/home12/icon/1.png";
import icon2 from "../../assets/img/choose/home12/icon/2.png";
import icon3 from "../../assets/img/choose/home12/icon/3.png";

import bgImg from "../../assets/img/bg/home12/why-learn-bg.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const WhyChooseUs = () => {
  const [faqData, setFaqData] = useState({
    risposta1: "",
    risposta2: "",
    risposta3: "",
    titolo1: "",
    titolo2: "",
    titolo3: "",
  });

  const [allData, setAllData] = useState({});

  const fetchfaqData = async () => {
    const query = `
    { 
      pageBy(pageId: 209) {
        faq {
          risposta1
          risposta2
          risposta3
          titolo1
          titolo2
          titolo3
        }
        pagineGeneriche {
          sintesi2
          immagine1 {
            mediaItemUrl
          }
          immagine2 {
            mediaItemUrl
          }
          immagine3 {
            mediaItemUrl
          }
          titoloAllegato
          allegato {
            mediaItemUrl
          }
          titoloAllegato2
          allegato2 {
            mediaItemUrl
          }
          titoloAllegato3
          allegato3 {
            mediaItemUrl
          }
          titoloAllegato4
          allegato4 {
            mediaItemUrl
          }
          titoloAllegato5
          allegato5 {
            mediaItemUrl
          }
          titoloAllegato6
          allegato6 {
            mediaItemUrl
          }
          titoloAllegato7
          allegato7 {
            mediaItemUrl
          }
          titoloAllegato8
          allegato8 {
            mediaItemUrl
          }
          titoloAllegato9
          allegato9 {
            mediaItemUrl
          }
          titoloAllegato10
          allegato10 {
            mediaItemUrl
          }
        }
      }
    }
    `;

    try {
      const response = await fetch(
        "https://gestione.istitutopoliziano.it/homo",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setFaqData(result.data?.pageBy.faq || {});
        setAllData(result.data?.pageBy.pagineGeneriche || {});
      } else {
        console.error("Errore nella richiesta GraphQL:", result.errors);
      }
    } catch (error) {
      console.error("Errore nella richiesta di rete:", error);
    }
  };

  useEffect(() => {
    fetchfaqData();
  }, []);

  if (!faqData || !allData) {
    return <div>Caricamento in corso...</div>;
  }

  const { titolo1, titolo2, titolo3, risposta1, risposta2, risposta3 } =
    faqData;

  const {
    titoloAllegato,
    titoloAllegato2,
    titoloAllegato3,
    titoloAllegato4,
    titoloAllegato5,
    titoloAllegato6,
    titoloAllegato7,
    titoloAllegato8,
    titoloAllegato9,
    titoloAllegato10,
    allegato,
    allegato2,
    allegato3,
    allegato4,
    allegato5,
    allegato6,
    allegato7,
    allegato8,
    allegato9,
    allegato10,
  } = allData;

  const tall1 = titoloAllegato || "";
  const tall2 = titoloAllegato2 || "";
  const tall3 = titoloAllegato3 || "";
  const tall4 = titoloAllegato4 || "";
  const tall5 = titoloAllegato5 || "";
  const tall6 = titoloAllegato6 || "";
  const tall7 = titoloAllegato7 || "";
  const tall8 = titoloAllegato8 || "";
  const tall9 = titoloAllegato9 || "";
  const tall10 = titoloAllegato10 || "";

  const all1 = allegato?.mediaItemUrl || "";
  const all2 = allegato2?.mediaItemUrl || "";
  const all3 = allegato3?.mediaItemUrl || "";
  const all4 = allegato4?.mediaItemUrl || "";
  const all5 = allegato5?.mediaItemUrl || "";
  const all6 = allegato6?.mediaItemUrl || "";
  const all7 = allegato7?.mediaItemUrl || "";
  const all8 = allegato8?.mediaItemUrl || "";
  const all9 = allegato9?.mediaItemUrl || "";
  const all10 = allegato10?.mediaItemUrl || "";

  return (
    <div className="why-choose-us style3" style={bgStyle}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 md-mb-40">
            <div className="img-part">
              <a href="https://www.itsacademymoda.it/">
                <img
                  src="https://www.itsacademymoda.it/wp-content/uploads/2023/08/perchescegliere-e1691407285828.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="col-lg-6 pl-60 md-pl-14">
            <div className="sec-title3 mb-30">
              <h2 className="title new-title margin-0 pb-14">
                ITS ACADEMY SISTEMA MODA
              </h2>
              <div className="new-desc">
                Il Poliziano è socio fondatore del ITS ACADEMY MODA
              </div>
            </div>
            <div className="services-part mb-20">
              <div className="services-icon">
                <img src={icon1} alt="" />
              </div>
              <div className="services-text">
                <h2 className="title">{titolo1}</h2>
                <p className="services-txt">{risposta1}</p>
              </div>
            </div>
            <div className="services-part mb-20">
              <div className="services-icon">
                <img src={icon2} alt="" />
              </div>
              <div className="services-text">
                <h2 className="title">{titolo2}</h2>
                <p className="services-txt">{risposta2}</p>
              </div>
            </div>
            <div className="services-part">
              <div className="services-icon">
                <img src={icon3} alt="" />
              </div>
              <div className="services-text">
                <h2 className="title">{titolo3}</h2>
                <p className="services-txt">{risposta3}</p>
              </div>
            </div>
            <div className="services-part mb-20 mt-20">
              <div className="services-text">
                <h2 className="title">Scopri di più</h2>
                <div className="ol">
                  <p>
                    <a href={all1 ? all1 : "#"}>{tall1 ? tall1 : ""}</a>
                  </p>
                  <p>
                    <a href={all2 ? all2 : "#"}>{tall2 ? tall2 : ""}</a>
                  </p>
                  <p>
                    <a href={all3 ? all3 : "#"}>{tall3 ? tall3 : ""}</a>
                  </p>
                  <p>
                    <a href={all4 ? all4 : "#"}>{tall4 ? tall4 : ""}</a>
                  </p>
                  <p>
                    <a href={all5 ? all5 : "#"}>{tall5 ? tall5 : ""}</a>
                  </p>
                  <p>
                    <a href={all6 ? all6 : "#"}>{tall6 ? tall6 : ""}</a>
                  </p>
                  <p>
                    <a href={all7 ? all7 : "#"}>{tall7 ? tall7 : ""}</a>
                  </p>
                  <p>
                    <a href={all8 ? all8 : "#"}>{tall8 ? tall8 : ""}</a>
                  </p>
                  <p>
                    <a href={all9 ? all9 : "#"}>{tall9 ? tall9 : ""}</a>
                  </p>
                  <p>
                    <a href={all10 ? all10 : "#"}>{tall10 ? tall10 : ""}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
