import React from 'react';
import { Link } from 'react-router-dom';

const FooterBottom = () => {
    return (
      <div className="footer-bottom">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 md-mb-10">
              <div className="copyright md-mb-0">
                <p>© 2023 Istituto Poliziano SRL p.iva 01807501000. </p>
              </div>
            </div>
            <div className="col-lg-6 text-end md-text-left">
              <ul className="copy-right-menu">
                <li>
                  <Link to="/event">Eventi</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/scuola/contatti">Contatti</Link>
                </li>
                <li>
                  <a href="https://angelomontini.com/" >
                    Credits
                  </a>
                </li>
              </ul>
            </div>
         
          </div>
        </div>
      </div>
    );
}

export default FooterBottom;