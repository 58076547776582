export const getIdFromPath = (pathname) => {
  switch (pathname) {
    case "/scuola/contatti":
      return "104";
    case "/servizi/navetta":
      return "211";
    case "/servizi/eventi":
      return "213";
    case "/servizi/bar":
      return "216";
    case "/servizi/parking":
      return "219";
    case "/didattica/tutoraggio":
      return "151";
    case "/successo/dada":
      return "153";
    case "/orientamento/didattico":
      return "182";
    case "/orientamento/uscita":
      return "184";
    case "/orientamento/sportello":
      return "186";
    case "/orientamento/personalizza":
      return "188";
    case "/didattica/inclusione":
      return "161";
    case "/didattica/regolamento":
      return "163";
    case "/didattica/patto":
      return "165";
    case "/didattica/ptof":
      return "167";
    case "/didattica/rav":
      return "169";
    case "/didattica/pcto":
      return "171";
    case "/didattica/consiglio":
      return "175";
    case "/didattica/ricevimento":
      return "177";
    case "/didattica/strumentali":
      return "179";
    case "/didattica/modulistica-studenti":
      return "595";
    case "/scuola/sede":
      return "190";
    case "/location/parco":
      return "192";
    case "/location/lab":
      return "195";
    case "/didattica/openair":
      return "198";
    case "/scuola/trasparente":
      return "202";
    case "/scuola/servizi":
      return "587";
    case "/scuola/presentazione":
      return "585";
    case "/lavora":
      return "106";
    case "/itsmoda":
      return "209";
    case "/privacy":
      return "3";
    case "/iscrizioni/iscrizioni-online":
      return "205";
    case "/iscrizioni/recupero":
      return "115";
    case "/registro":
      return "207";
    case "/docs":
      return "684";
    default:
      return "10";
  }
};
