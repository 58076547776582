import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const RSMobileMenu = ({ menuOpen, parentMenu,  headerFullWidth }) => {

	const location = useLocation();

	const [home, setHome] = useState(false)
  const [Scuola, setScuola] = useState(false);
	const [Corsi, setCorsi] = useState(false)
	const [Didattica, setDidattica] = useState(false)
  const [Iscrizioni, setIscrizioni] = useState(false);
	const [Informa, setInforma] = useState(false)


	const openMobileMenu = menu => {

		if (menu === "home") {
      setHome(!home);
      setScuola(false);
      setCorsi(false);
      setIscrizioni(false);
      setDidattica(false);
    } else if (menu === "Scuola") {
      setHome(false);
      setScuola(!Scuola);
      setCorsi(false);
      setIscrizioni(false);
      setDidattica(false);
    } else if (menu === "Corsi") {
      setHome(false);
      setScuola(false);
      setCorsi(!Corsi);
      setIscrizioni(false);
      setDidattica(false);
    } else if (menu === "Iscrizioni") {
      setHome(false);
      setScuola(false);
      setCorsi(false);
      setIscrizioni(!Iscrizioni);
      setDidattica(false);
    } else if (menu === "Didattica") {
      setHome(false);
      setScuola(false);
      setCorsi(false);
      setIscrizioni(false);
      setDidattica(!Didattica);
    } else if (menu === "Informa") {
      setHome(false);
      setScuola(false);
      setCorsi(false);
      setIscrizioni(false);
      setDidattica(false);
      setInforma(!Informa);
    }
	};

	return (
    <div
      className={
        headerFullWidth ? "container-fluid relative" : "container relative"
      }
    >
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <li>
              <Link
                to="/"
                className={parentMenu === "homex" ? "active-menu" : ""}
              >
                Home
              </Link>
            </li>
            <li
              className={
                Scuola
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("Scuola");
                }}
                className={parentMenu === 583 ? "active-menu" : ""}
              >
                Scuola
              </Link>
              <ul className={Scuola ? "sub-menu current-menu" : "sub-menu"}>
                <li>
                  <Link
                    to="/scuola/presentazione"
                    className={
                      location.pathname === "/scuola/presentazione"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Presentazione
                  </Link>
                </li>

                <li>
                  <Link
                    to="/scuola/sede"
                    className={
                      location.pathname === "/scuola/sede" ? "active-menu" : ""
                    }
                  >
                    Sede
                  </Link>
                </li>
                <li>
                  <Link
                    to="/scuola/servizi"
                    className={
                      location.pathname === "/scuola/servizi"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Servizi
                  </Link>
                </li>
                <li>
                  <Link
                    to="/scuola/contatti"
                    className={
                      location.pathname === "/scuola/contatti"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Contatti Segreteria
                  </Link>
                </li>
                <li>
                  <Link
                    to="/scuola/trasparente"
                    className={
                      location.pathname === "/scuola/trasparente"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Amministrazione trasparente
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                Corsi
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("Corsi");
                }}
                className={parentMenu === 96 ? "active-menu" : ""}
              >
                Corsi di studio
              </Link>
              <ul className={Corsi ? "sub-menu current-menu" : "sub-menu"}>
                <li>
                  <Link
                    to="/corsi/scientifico"
                    className={
                      location.pathname === "/corsi/scientifico"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Liceo Scientifico
                  </Link>
                </li>
                <li>
                  <Link
                    to="/corsi/applicate"
                    className={
                      location.pathname === "/corsi/applicate"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Liceo Scientifico Scienze Applicate
                  </Link>
                </li>
                <li>
                  <Link
                    to="/corsi/umane"
                    className={
                      location.pathname === "/corsi/umane" ? "active-menu" : ""
                    }
                  >
                    Liceo delle Scienze Umane
                  </Link>
                </li>
                <li>
                  <Link
                    to="/corsi/linguistico"
                    className={
                      location.pathname === "/corsi/linguistico"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Liceo Linguistico
                  </Link>
                </li>
                <li>
                  <Link
                    to="/corsi/sociale"
                    className={
                      location.pathname === "/corsi/sociale"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Liceo delle Scienze Umane Eco/Soc
                  </Link>
                </li>
                <li>
                  <Link
                    to="/corsi/amministrazione"
                    className={
                      location.pathname === "/corsi/amministrazione"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Amministrazione, Finanza, Marketing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/corsi/madeitaly"
                    className={
                      location.pathname === "/corsi/madeitaly"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Liceo Made in Italy
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                Didattica
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("Didattica");
                }}
                className={parentMenu === 102 ? "active-menu" : ""}
              >
                Didattica
              </Link>
              <ul className={Didattica ? "sub-menu current-menu" : "sub-menu"}>
                <li>
                  <Link
                    to="/docs"
                    onClick={() => {
                      openMobileMenu("Documenti");
                    }}
                    className={parentMenu === 102 ? "active-menu" : ""}
                  >
                    Documenti
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link
                        to="/didattica/consiglio"
                        className={
                          location.pathname === "/didattica/consiglio"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Documenti Consiglio di Classe
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/didattica/regolamento"
                        className={
                          location.pathname === "/didattica/regolamento"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Regolamento di istituto
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/didattica/rav"
                        className={
                          location.pathname === "/didattica/rav"
                            ? "active-menu"
                            : ""
                        }
                      >
                        RAV
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/didattica/ptof"
                        className={
                          location.pathname === "/didattica/ptof"
                            ? "active-menu"
                            : ""
                        }
                      >
                        PTOF
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/didattica/patto"
                        className={
                          location.pathname === "/didattica/patto"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Patto di corrensponsabilità
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="/didattica/strumentali"
                    className={
                      location.pathname === "/didattica/strumentali"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Figure strumentali
                  </Link>
                </li>
                <li>
                  <Link
                    to="/didattica/pcto"
                    className={
                      location.pathname === "/didattica/pcto"
                        ? "active-menu"
                        : ""
                    }
                  >
                    PCTO
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="#"
                    className={parentMenu === 155 ? "active-menu" : ""}
                  >
                    Orientamento
                  </Link>

                <ul className="sub-menu">
                    <li>
                      <Link
                        to="/orientamento/didattico"
                        className={
                          location.pathname === "/orientamento/didattico"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Orientamento didattico trasversale
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/orientamento/uscita"
                        className={
                          location.pathname === "/orientamento/uscita"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Orientamento in uscita
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/orientamento/sportello"
                        className={
                          location.pathname === "/orientamento/sportello"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Sportello ascolto
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/orientamento/personalizza"
                        className={
                          location.pathname === "/orientamento/personalizza"
                            ? "active-menu"
                            : ""
                        }
                      >
                        Personalizzazione didattica
                      </Link>
                    </li>
                  </ul> 
                </li>*/}
                <li>
                  <Link
                    to="/didattica/tutoraggio"
                    className={
                      location.pathname === "/didattica/tutoraggio"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Tutoraggio online
                  </Link>
                </li>
                <li>
                  <Link
                    to="/didattica/openair"
                    className={
                      location.pathname === "/didattica/openair"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Lezioni all'aperto
                  </Link>
                </li>
                <li>
                  <Link
                    to="/didattica/ricevimento"
                    className={
                      location.pathname === "/didattica/ricevimento"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Orari di ricevimento
                  </Link>
                </li>
                <li>
                  <Link
                    to="/didattica/modulistica-studenti"
                    className={
                      location.pathname === "/didattica/modulistica-studenti"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Modulistica studenti
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog"
                    className={
                      location.pathname === "/blog" ? "active-menu" : ""
                    }
                  >
                    Giornalino scolastico
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                Iscrizioni
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("Iscrizioni");
                }}
                className={parentMenu === 591 ? "active-menu" : ""}
              >
                Iscrizioni
              </Link>
              <ul className={Iscrizioni ? "sub-menu current-menu" : "sub-menu"}>
                <li>
                  <Link
                    to="/iscrizioni/iscrizioni-online"
                    className={
                      location.pathname === "/iscrizioni/iscrizioni-online"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Iscrizioni A.S. 2024/2025
                  </Link>
                </li>
                <li>
                  <Link
                    to="/iscrizioni/recupero"
                    className={
                      location.pathname === "/iscrizioni/recupero"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Recupero anni scolastici
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={
                Informa
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("Informa");
                }}
                className={
                  parentMenu === 100 || parentMenu === 157 || parentMenu === 159
                    ? "active-menu"
                    : ""
                }
              >
                Poliziano Informa
              </Link>
              <ul className={Informa ? "sub-menu current-menu" : "sub-menu"}>
                <li>
                  <Link
                    to="/event"
                    className={
                      location.pathname === "/event" ? "active-menu" : ""
                    }
                  >
                    Eventi
                  </Link>
                </li>

                <li>
                  <Link
                    to="/lavora"
                    className={
                      location.pathname === "/lavora" ? "active-menu" : ""
                    }
                  >
                    Lavora con noi
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default RSMobileMenu;